@charset "utf-8";
// ______________________________________
// ______________________________________
// ______________________________________
main.mainContact{
// ______________________________________
// ______________________________________
// ______________________________________
.contact_wrap{
  min-height: 100%;
  position: relative;
  .contact_box{
    @include posi_c_c;
  }
}
// ______________________________________
@media screen and (max-width: $media_sp){
  .contact_wrap{
    // min-height: 100%;
    // position: relative;
    .contact_box{
      // @include posi_c_c;
    }
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
.field_box{
  @include flex;
  width: 400px;
  margin: 20px 0;
}
.btn_box{
  margin: 50px 0 0;
  text-align: center;
}
#name-error-message ,
#email-error-message ,
#inquiry-error-message {
  font-size: 11px;
  color: red;
  margin: 10px 0 0;
}

/* エラーメッセージの非表示 */
#name-error-message ,
#email-error-message ,
#inquiry-error-message {
  display: none;
}
// ______________________________________
@media screen and (max-width: $media_sp){
  .field_box{
    // @include flex;
    max-width: 400px;
    width: $vw_680px;
    margin: $vw_60px 0;
  }
  .btn_box{
    margin: $vw_60px 0 0;
    // text-align: center;
  }
  #name-error-message ,
  #email-error-message ,
  #inquiry-error-message {
    // font-size: 11px;
    // color: red;
    // margin: 10px 0 0;
  }
  
  /* エラーメッセージの非表示 */
  #name-error-message ,
  #email-error-message ,
  #inquiry-error-message {
    // display: none;
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
.thanks_wrap{
  min-height: 100%;
  position: relative;
  .thanks_box{
    @include posi_c_c;
    width: 100%;
    .ttl{
      font-size: 28px;
      text-align: center;
    }
    .lead{
      font-size: 14px;
      line-height: 1.8;
      text-align: center;
      margin: 20px 0 0;
    }
  }
}
// ______________________________________
@media screen and (max-width: $media_sp){
  .thanks_wrap{
    // min-height: 100%;
    // position: relative;
    .thanks_box{
      // @include posi_c_c;
      // width: 100%;
      .ttl{
        font-size: $vw_40px;
        font-size: clamp(10px, $vw_40px, 28px);
        // text-align: center;
      }
      .lead{
        font-size: $vw_24px;
        font-size: clamp(10px, $vw_24px, 14px);
        // line-height: 1.8;
        // text-align: center;
        margin: $vw_20px 0 0;
      }
    }
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
/* main.mainContact ___end*/}