@charset "utf-8";
// ______________________________________
// ______________________________________
// ______________________________________
main.mainCompany{
// ______________________________________
// ______________________________________
// ______________________________________
.company_wrap{
  min-height: 100%;
  position: relative;
  .company_box{
    @include flex_c;
    flex-direction: row-reverse;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    .img_box{
      width: 260px;
      min-height: 295px;
      margin: 0 20px;
      background: {
        // image: url("/public/img/company/company.jpg");
        repeat: no-repeat;
        size: cover;
        position: 10% center;
      }
    }
    .data_box{
      width: 490px;
      margin: 0 20px;
    }
    .top_box{
      @include flex_b;
      align-items: center;
    }
    .name_box{
      .p1{
        font-size: 16px;
      }
      .p2{
        font-size: 10px;
        margin: 15px 0 0;
      }
    }
    .business_box{
      padding: 0 0 0 20px;
      border-left: solid 1px rgba(#3e3a39, .1);
      p,span{
        font-weight: 200;
      }
      p{
        font-size: 12px;
        margin: 15px 0;
      }
      span{
        display: inline-block;
        font-size: 10px;
        line-height: 2;
        margin: 5px 0 0;
      }
    }
    .btm_box{
      padding: 20px 0 0;
      border-top: solid 1px rgba(#3e3a39, .1);
      margin: 30px 0 0;
    }
    .address_box{
      @include flex;
      .p1{
        font-size: 10px;
        line-height: 2.2;
        margin: 0 40px 0 0;
      }
      .p2, a{
        font-size: 12px;
        font-weight: 200;
        line-height: 2;
        pointer-events: none;
      }
    }
    .map_box{
        margin: 20px 0 0;
      filter: grayscale(1);
      iframe{
        width: 100%;
        height: 200px;;
      }
    }
    .access_box{
      @include flex;
      align-items: center;
      margin: 10px 0 0;
      .p1{
        font-size: 10px;
        margin: 0 15px 0 0;
      }
      .p2{
        padding: 0 0 0 15px;
        font-size: 10px;
        font-weight: 200;
        line-height: 1.6;
        letter-spacing: 0;
        position: relative;
      }
      .p2::before{
        content: "";
        width: 1px;
        height: 100%;
        background: rgba(#3e3a39, .3);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
// ______________________________________
@media screen and (max-width: $media_sp){
  .company_wrap{
    // min-height: 100%;
    // position: relative;
    .company_box{
      @include flex_c;
      width: $vw_680px;
      position: relative;
      top: auto;
      transform: none;
      margin: $vw_60px auto;
      .img_box{
        max-width: 490px;
        width: 100%;
        min-height: auto;
        max-height: 350px;
        height: $vw_350px * 1.5;
        margin: 0 0 $vw_40px;
        background: {
          // image: url("/public/img/company/company.jpg");
          // repeat: no-repeat;
          // size: cover;
          position: left center;
        }
      }
      .data_box{
        max-width: 490px;
        width: 100%;
        margin: 0;
      }
      .top_box{
        // @include flex_b;
        // align-items: center;
      }
      .name_box{
        margin: $vw_20px 0;
        .p1{
          font-size: $vw_26px;
          font-size: clamp(10px, $vw_26px, 16px);
        }
        .p2{
          font-size: $vw_20px;
          font-size: clamp(10px, $vw_20px, 10px);
          margin: $vw_15px 0 0;
        }
      }
      .business_box{
        padding: 0 0 0 $vw_20px;
        // border-left: solid 1px rgba(#3e3a39, .1);
        p,span{
          // font-weight: 200;
        }
        p{
          font-size: $vw_24px;
          font-size: clamp(10px, $vw_24px, 12px);
          margin: $vw_15px 0;
        }
        span{
          // display: inline-block;
          font-size: $vw_20px;
          font-size: clamp(10px, $vw_20px, 10px);
          // line-height: 2;
          margin: $vw_5px 0 0;
        }
      }
      .btm_box{
        padding: $vw_60px 0 0;
        // border-top: solid 1px rgba(#3e3a39, .1);
        margin: $vw_60px 0 0;
      }
      .address_box{
        display: block;
        .p1{
          font-size: $vw_20px;
          font-size: clamp(10px, $vw_20px, 10px);
          margin: 0 0 $vw_10px;
        }
        .p2, a{
          font-size: $vw_22px;
          font-size: clamp(10px, $vw_22px, 12px);
          // font-weight: 200;
          // line-height: 2;
          pointer-events: auto;
        }
      }
      .map_box{
        margin: $vw_20px 0 0;
        // filter: grayscale(1);
        iframe{
          // width: 100%;
          max-height: 200px;;
          height: $vw_200px * 1.5;;
        }
      }
      .access_box{
        // @include flex;
        // align-items: center;
        margin: $vw_10px 0 0;
        .p1{
          font-size: $vw_20px;
          font-size: clamp(10px, $vw_20px, 10px);
          margin: $vw_25px $vw_15px $vw_25px 0;
        }
        .p2{
          padding: 0 0 0 $vw_15px;
          font-size: $vw_20px;
          font-size: clamp(10px, $vw_20px, 10px);
          // font-weight: 200;
          // line-height: 1.6;
          // position: relative;
        }
        .p2::before{
          // content: "";
          // width: 1px;
          // height: 100%;
          // background: rgba(#3e3a39, .3);
          // position: absolute;
          // top: 0;
          // left: 0;
        }
      }
    }
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
/* main.mainCompany ___end*/}