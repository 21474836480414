@charset "utf-8";
*{
  // transition: all 0.3s ease;
  margin: 0;
  padding: 0;
  color: #231815;
  font-family: 'NotoSansJP-subset', sans-serif;
}
.SansJP{font-family: 'NotoSansJP-subset', sans-serif;}
.IBM{font-family: 'IBMPlexSans-Regular', sans-serif;}

html{overflow: auto;}
body{-webkit-text-size-adjust: 100%;}
main{
  display: block;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
section{
  position: relative;
}
div.content{
  width: 96%;
  margin: 0 auto;
}
h2, p, span, a, li, th, td, small, label{
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1em;
  font-feature-settings: "palt";
}
img, video{
  width: 100%;
  vertical-align: bottom;
}
.note{
  font-size: 10px;
  line-height: 1.4;
}
.caption{
  font-size: 10px;
  letter-spacing: 0;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0,0,0,.5), -1px 1px 3px rgba(0,0,0,.5), 1px -1px 3px rgba(0,0,0,.5), -1px -1px 3px rgba(0,0,0,.5);
  position: absolute;
  bottom: 5px;
  right: 5px;
}
span.sup{
  font-size: 10px;
  vertical-align: top;
  position: relative;
  top: -0.1em;
}
.co_000{color: #000;}
.co_fff{color: #fff;}
.t_shdw_000{text-shadow: 1px 1px 3px rgba(0,0,0,.5), -1px 1px 3px rgba(0,0,0,.5), 1px -1px 3px rgba(0,0,0,.5), -1px -1px 3px rgba(0,0,0,.5);}
.txt_le{text-align: left;}
.txt_ri{text-align: right;}
.slick-slider div{transition: none;}
.rellax{transition: all 0s ease;}
@media screen and (min-width: $media_pc){._sp{display: none !important;}}
@media screen and (max-width: $media_sp){._pc{display: none !important;}}
@media screen and (min-width: ($nav_sp + 1)){._nav{display: none !important;}}
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
html{
  overflow: hidden;
}
.mainWrap{
  transition: all 0s ease;
}
main{
  min-height: 100%;
  margin: 0 0 0;
}
div.content{max-width: 1000px;}
@media screen and (max-width: $nav_sp){
  main{margin: 0px 0 0;}
/*@media screen end*/}
@media screen and (max-width: $media_sp){
  div.content{width: $vw_680px;}
/*@media screen end*/}
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// common
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(#231815,.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
// ______________________________________
@media screen and (max-width: $media_sp){
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    // background: rgba(0,0,0,0);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    // background: rgba(#231815,.3);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
.loadwrap{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  background: #fff;
  opacity: 1;
  &.no_active{
    animation-name: loadAnime;
    animation-duration: .5s;
    animation-fill-mode:forwards;
    // animation-timing-function: linear;
    animation-timing-function: ease-in;
  }
}
@keyframes loadAnime {
  0% {
    z-index: 99999;
    opacity: 1;
  }
  90% {
    z-index: 99999;
  }
  100% {
    z-index: -1;
    opacity: 0;
  }
}
// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
.start_load_wrap{
  transition: all 1.5s cubic-bezier(0.77,0,0.18,1);;
  width: 100%;
  height: 100vh;
  background: #fff;
  position: relative;
  margin: 0vh 0 0;
  .svg_box{
    @include posi_c_c;
    width: 300px;
  }
}
.start_load_wrap.no_active{
  margin: -100vh 0 0;
}
// ______________________________________
@media screen and (max-width: $media_sp){
  .start_load_wrap{
    // transition: all 1.5s cubic-bezier(0.77,0,0.18,1);;
    // width: 100%;
    // height: 100vh;
    // background: #fff;
    // position: relative;
    // margin: 0vh 0 0;
    .svg_box{
      // @include posi_c_c;
      width: 200px;
    }
  }
  .start_load_wrap.no_active{
    // margin: -100vh 0 0;
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
.mainWrap{
  will-change: animation,position,transform;
}
// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// header
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
header{
  @include flex_c_c;
  transition: all 1.5s cubic-bezier(0.77,0,0.18,1);;
  width: 100%;
  height: 90px;
  background: #fff;
  position: fixed;
  top: 0vh;
  left: 0;
  z-index: 9999;
  .header{
    @include flex_b;
    align-items: flex-end;
    // max-width: 1200px;
    width: calc(100% - 100px);
    .logo{
      width: 70px;
    }
  }
}
// ______________________________________
@media screen and (max-width: $nav_sp){
  header{
    // @include flex_c_c;
    // transition: all 1.5s cubic-bezier(0.77,0,0.18,1);;
    // width: 100%;
    height: 90px;
    // background: #fff;
    // background: #ccc;
    // position: fixed;
    // top: 0vh;
    // left: 0;
    // z-index: 9999;
    .header{
      @include flex_b;
      align-items: center;
      // max-width: 1200px;
      width: $vw_680px;
      .logo{
        width: 56px;
        margin: 0 auto 1px;
      }
    }
  }
/*@media screen end*/}
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// nav
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
nav{
  width: calc(100% - 26px - 70px - 30px);
  margin: 0 30px 0 0;
  ul{
    @include flex_e;
    li{
      transition: all .75s ease;
      overflow: hidden;
      width: 0;
      opacity: .5;
    }
    li:hover{
      opacity: 1;
    }
    a{
      padding: 5px 10px;
    }
    span{
      font-size: 11px;
    }
  }
}
nav.active{
  ul{
    li.top{width: 44px;}
    li.projects{width: 82px;}
    li.message{width: 77px;}
    li.company{width: 79px;}
    li.contact{width: 76px;}
  }
}
nav.top{
  ul{
    li.top{
      width: 44px;
      opacity: 1;
    }
  }
}
nav.projects{
  ul{
    li.projects{
      width: 82px;
      opacity: 1;
    }
  }
}
nav.message{
  ul{
    li.message{
      width: 77px;
      opacity: 1;
    }
  }
}
nav.company{
  ul{
    li.company{
      width: 79px;
      opacity: 1;
    }
  }
}
nav.contact{
  ul{
    li.contact{
      width: 76px;
      opacity: 1;
    }
  }
}
nav.thanks{
  ul{
    li.contact{
      width: 76px;
      opacity: 1;
    }
  }
}
.btn-trigger{
  width: 26px;
  height: 11px;
  position: relative;
  cursor: pointer;
}
.btn-trigger span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  background: #231815;
}
.btn-trigger, .btn-trigger span {
  display: inline-block;
  transition: all .75s;
  box-sizing: border-box;
}
.btn-trigger span:nth-of-type(1) {
  top: 0px;
}
.btn-trigger span:nth-of-type(2) {
  top: 5px;
}
.btn-trigger span:nth-of-type(3) {
  bottom: 0px;
}
.btn-trigger span:nth-of-type(1) {animation: btn07-bar01 .75s forwards;}
@keyframes btn07-bar01 {
  0% {transform: translateY(5px) rotate(45deg);}
  50% {transform: translateY(5px) rotate(0);}
  100% {transform: translateY(0) rotate(0);}
}
.btn-trigger span:nth-of-type(2) {transition: all .25s .25s;opacity: 1;}
.btn-trigger span:nth-of-type(3) {animation: btn07-bar03 .75s forwards;}
@keyframes btn07-bar03 {
  0% {transform: translateY(-5px) rotate(-45deg);}
  50% {transform: translateY(-5px) rotate(0);}
  100% {transform: translateY(0) rotate(0);}
}
.btn-trigger.active span:nth-of-type(1) {animation: active-btn07-bar01 .75s forwards;}
@keyframes active-btn07-bar01 {
  0% {transform: translateY(0) rotate(0);}
  50% {transform: translateY(5px) rotate(0);}
  100% {transform: translateY(5px) rotate(45deg);}
}
.btn-trigger.active span:nth-of-type(2) {opacity: 0;}
.btn-trigger.active span:nth-of-type(3) {animation: active-btn07-bar03 .75s forwards;}
@keyframes active-btn07-bar03 {
  0% {transform: translateY(0) rotate(0);}
  50% {transform: translateY(-5px) rotate(0);}
  100% {transform: translateY(-5px) rotate(-45deg);}
}
// ______________________________________
@media screen and (max-width: $nav_sp){
  nav{
    width: calc(100% - 26px - 10px);
    margin: 0 10px 3px 0;
    ul{
      // @include flex_e;
      li{
        // transition: all .75s ease;
        // overflow: hidden;
        // width: 0;
        // opacity: .5;
      }
      li:hover{
        // opacity: 1;
      }
      a{
        display: inline-block;
        padding: 5px 5px;
      }
      span{
        font-size: 11px;
        letter-spacing: 0;
      }
    }
  }
  nav.active{
    ul{
      li.top{width: 31px;}
      li.projects{width: 63px;}
      li.message{width: 59px;}
      li.company{width: 61px;}
      li.contact{width: 58px;}
    }
  }
  nav.top{
    ul{
      li.top{
        width: 31px;
        opacity: 1;
      }
    }
  }
  nav.projects{
    ul{
      li.projects{
        width: 63px;
        opacity: 1;
      }
    }
  }
  nav.message{
    ul{
      li.message{
        width: 59px;
        opacity: 1;
      }
    }
  }
  nav.company{
    ul{
      li.company{
        width: 61px;
        opacity: 1;
      }
    }
  }
  nav.contact{
    ul{
      li.contact{
        width: 58px;
        opacity: 1;
      }
    }
  }
  nav.thanks{
    ul{
      li.contact{
        width: 58px;
        opacity: 1;
      }
    }
  }
/*@media screen end*/}
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// footer
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ______________________________________
footer{}
// ______________________________________
@media screen and (max-width: $media_sp){
  footer{}
/*@media screen end*/}