@charset "utf-8";
// ______________________________________
// ______________________________________
// ______________________________________
main.mainProjects{
// ______________________________________
// ______________________________________
// ______________________________________
.projects_wrap{
  min-height: 100%;
  margin: 60px 0 0;
  .projects_box{
    @include flex;
    margin: 0 25px;
    .list{
      width: calc(25% - 50px);
      margin: 0 25px 25px;
      .img{
        display: block;
        overflow: hidden;
        img{
          transition: all 0.6s ease;
        }
      }
      .img:hover{
        img{
          transform: scale(1.1);
        }
      }
      p{
        font-size: 12px;
        font-weight: 100;
        color: #595757;
        text-align: center;
        margin: 5px 0 0;
      }
    }
  }
}
// ______________________________________
@media screen and (min-width:$media_pc) and ( max-width:1000px) {
  .projects_wrap{
    .projects_box{
      .list{
        width: calc(25% - 30px);
        margin: 0 15px 25px;
      }
    }
  }
/*@media screen end*/}
// ______________________________________
@media screen and (min-width:1600px) { 
  .projects_wrap{
    .projects_box{
      .list{
        width: calc(20% - 50px);
        margin: 0 25px 25px;
      }
    }
  }
/*@media screen end*/}
// ______________________________________
@media screen and (max-width: $media_sp){
  .projects_wrap{
    // min-height: 100%;
    margin: $vw_60px 0 0;
    .projects_box{
      @include flex_b;
      width: $vw_680px;
      margin: 0 auto;
      .list{
        width: 47%;
        margin: 0 0 $vw_60px;
        .img{
          // display: block;
          // overflow: hidden;
          img{
            // transition: all 0.6s ease;
          }
        }
        .img:hover{
          img{
            transform: scale(1);
          }
        }
        p{
          font-size: $vw_22px;
          font-size: clamp(10px, $vw_22px, 12px);
          // font-weight: 100;
          // color: #595757;
          // text-align: center;
          margin: $vw_10px 0 0;
        }
      }
    }
  }
  /*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
/* main.mainProjects ___end*/}
// ______________________________________
// ______________________________________
// ______________________________________
main.mainProjectsDetails{
// ______________________________________
// ______________________________________
// ______________________________________
.projects_wrap{
  margin: 50px 0 0;
  .projects_ttl_box{
    height: 50px;
    .ttl{
      font-size: 18px;
      font-weight: 100;
      color: #595757;
      margin: 0 0 30px;
    }
    .txt{
      font-size: 12px;
      margin: 10px 0 0;
    }
  }
  .projects_box{
    max-width: 600px;
    margin: 0 auto;
    // position: relative;
    // z-index: 1;
    .img{
      margin: 0 0 80px;
    }
    .img.mb-0{
      margin: 0 0 0px;
    }
    .img.mb-10{
      margin: 0 0 10px;
    }
    .img.mb-40{
      margin: 0 0 40px;
    }
    .youtube{
       position: relative;
      width: 100%;
      padding-top: 56.25%;
      margin: 0 0 80px;
      iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
    .ttl{
      font-size: 14px;
      letter-spacing: 0.03em;
      margin: 0 0 10px;
    }
  }
}
// ______________________________________
@media screen and (max-width: $media_sp){
  .projects_wrap{
    margin: $vw_60px 0 0;
    .projects_ttl_box{
      height: auto;
      .ttl{
        font-size: $vw_28px;
        font-size: clamp(10px, $vw_28px, 18px);
        // font-weight: 100;
        // color: #595757;
        margin: 0 0 $vw_30px;
      }
      .txt{
        font-size: $vw_22px;
        font-size: clamp(10px, $vw_22px, 12px);
        margin: $vw_10px 0 0;
      }
    }
    .projects_box{
      max-width: 600px;
      width: $vw_680px;
      margin: $vw_50px auto 0;
      // position: relative;
      // z-index: 1;
      .img{
        margin: 0 0 $vw_80px;
      }
      .img.mb-0{
        margin: 0 0 0px;
      }
      .img.mb-10{
        margin: 0 0 $vw_10px;
      }
      .img.mb-40{
        margin: 0 0 $vw_40px;
      }
      .ttl{
        font-size: $vw_24px;
        font-size: clamp(10px, $vw_24px, 14px);
        // letter-spacing: 0.03em;
        margin: 0 0 $vw_10px;
      }
    }
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
/* main.mainProjectsDetails ___end*/}