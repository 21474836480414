@charset "utf-8";
// ______________________________________
// ______________________________________
// ______________________________________
main.mainTop{
// ______________________________________
// ______________________________________
// ______________________________________
.projects_wrap{
  .slick-slide *{
    will-change: animation,position,transform;
    // backface-visibility: hidden;
  }
  .slide_list{
    overflow: hidden;
    // cursor: pointer;
    .slide_cover{
      overflow: hidden;
      margin: 0 20px;
      position: relative;
    }
    .imgObverse,.imgReverse{
      display: block;
      width: calc(100% - 2px);
      padding: 1px;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
    }
    .img{
      transition: all 0.6s ease;
      display: block;
    }
  }
  .slide_list:hover{
    .img{
      transform: scale(1.1)
    }
  }
  
  .slide_box{
    @include flex_c;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .slide{
    margin: 0.8vh 0;
  }
}
// ______________________________________
@media screen and (max-width: $media_sp){
  .projects_wrap{
    .slick-initialized .slick-slide{
      // transition: all 0s;
      // will-change: animation,position,transform;
      // backface-visibility: hidden;
    }
    .slide_list{
      // overflow: hidden;
      // cursor: pointer;
      .slide_cover{
        // overflow: hidden;
        margin: 0 10px;
      }
      img{
        // transition: all 0.6s ease;
        // display: block;
      }
    }
    .slide_list:hover{
      img{
        // transform: scale(1.1)
      }
    }
    
    .slide_box{
      // @include flex_c;
      // flex-direction: column;
      // flex-wrap: nowrap;
      // height: 100%;
      // width: 100%;
      // position: absolute;
      // top: 0;
      // left: 0;
    }
    .slide{
      margin: 0.8vh 0;
    }
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
.slide_box{
  // z-index: -1;
}
.slide_box.active{
  // z-index: 1;
}
.slide_box{
  .slide_cover{
    transition: all 1s ease;
    opacity: 0;
    transform:rotateY(270deg);
    animation-duration: 1s;
    animation-fill-mode:forwards;
    animation-timing-function: linear;
    // animation-timing-function: ease-in;
    .imgObverse,.imgReverse{
      animation-duration: 1s;
      animation-fill-mode:forwards;
      animation-timing-function: linear;
      // animation-timing-function: ease-in;
    }
    .imgObverse{
      transform: translateZ(2px);
      z-index: 2;
      .img{
        img{
          transform: rotateY(0deg);
        }
      }
    }
    .imgReverse{
      transform: translateZ(1px);
      z-index: 1;
      .img{
        img{
          transform: rotateY(180deg);
        }
      }
    }
  }
  .slide_cover.obverse{
    opacity: 1;
    animation-name: rotateY_obverse;
    .imgObverse{animation-name: zindex_on;}
    .imgReverse{animation-name: zindex_off;}
  }
  .slide_cover.reverse{
    opacity: 1;
    animation-name: rotateY_reverse;
    .imgObverse{animation-name: zindex_off;}
    .imgReverse{animation-name: zindex_on;}
  }
  @keyframes rotateY_obverse {
    0% {
      transform:rotateY(180deg);
    }
    100% {
      transform:rotateY(360deg);
    }
  }
  @keyframes rotateY_reverse {
    0% {
      transform:rotateY(360deg);
    }
    100% {
      transform:rotateY(180deg);
    }
  }
  @keyframes zindex_on {
    0% {z-index: 1;transform: translateZ(1px);}
    90% {z-index: 2;transform: translateZ(2px);}
    100% {z-index: 2;transform: translateZ(2px);}
  }
  @keyframes zindex_off {
    0% {z-index: 2;transform: translateZ(2px);}
    90% {z-index: 1;transform: translateZ(1px);}
    100% {z-index: 1;transform: translateZ(1px);}
  }
}

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
/* main.mainTop ___end*/}