@charset "utf-8";
// ______________________________________
// ______________________________________
// ______________________________________
main.mainMessage{
// ______________________________________
// ______________________________________
// ______________________________________
.message_wrap{
  min-height: 100%;
  position: relative;
  .message_box{
    @include posi_c_c;
    .p1{
      font-size: 14px;
      line-height: 3;
    }
    .p2{
      font-size: 11px;
      line-height: 2;
      margin: 20px 0 0;
    }
  }
}
// ______________________________________
@media screen and (max-width: $media_sp){
  .message_wrap{
    // min-height: 100%;
    // position: relative;
    .message_box{
      @include posi_c_c;
      max-width: $vw_680px;
      width: 100%;
      .p1{
        font-size: $vw_24px;
        font-size: clamp(10px, $vw_24px, 14px);
        letter-spacing: 0.03em;
        // line-height: 3;
      }
      .p2{
        font-size: $vw_22px;
        font-size: clamp(10px, $vw_22px, 11px);
        // line-height: 2;
        margin: $vw_20px 0 0;
      }
    }
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@media screen and (max-width: $media_sp){
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
/* main.mainMessage ___end*/}